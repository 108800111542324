<div class="bg__sidebar_left"></div>

<div id="sidebar__main" class="sidebar_left select-none">
	<div class="w-full block h-full lg:bg-[#f7f7f7]">
		<div class="w-full h-full overflow-hidden lg:bg-white pb-6 grid grid-cols-1 grid-rows-[minmax(0,max-content)_minmax(0,1fr)]">
			<div class="w-[150px] h-[80px] my-5 max-w-full block mx-auto" routerDirection="root" href="/">
				<img [src]="env.imgLogo" [alt]="env.title" class="mx-auto block py-4 h-full object-contain">
			</div>
			<div class="content__scroll-sidebar max-h-full h-full w-full overflow-x-hidden overflow-y-auto flex flex-col justify-between" data-scrollbar >
				<ul class="h-auto list-none block w-full ">
					@for (item of menu; track item.name) {
						<li  class="px-2 block w-full ">
							@if (item.submenu) {
								<div class="p-3 w-full relative">
									<div class="py-2 grid grid-cols-[minmax(0,1fr)_minmax(0,30px)] items-center text-secondary">
										<h5 class="font-medium text-base">{{ item.name }}</h5>
										<i class="far fa-chevron-down self-start"></i>
									</div>
									<ul class="h-auto list-none block w-full">
										@for (itemMenu of (item?.submenu ?? []); track itemMenu.name) {
											<li class="block px-2">
												<a [href]="itemMenu.url" target="_blank" class="text-secondary grid grid-cols-[minmax(0,30px)_minmax(0,1fr)] p-3 hover:bg-gray-100 rounded-lg w-full">
													<ng-container *ngTemplateOutlet="itemRoute; context: { $implicit: itemMenu}"></ng-container>
												</a>
											</li>
										}
									</ul>
								</div>
							} @else {
								@if(item.external) {
									<a [href]="item.url" target="_blank" class="text-secondary grid grid-cols-[minmax(0,30px)_minmax(0,1fr)] p-3 hover:bg-gray-100 rounded-lg w-full">
										<ng-container *ngTemplateOutlet="itemRoute; context: { $implicit: item}"></ng-container>
									</a>
								} @else {
									<a [routerLink]="item.url" routerDirection="root" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="btn__route text-secondary grid grid-cols-[minmax(0,30px)_minmax(0,1fr)] p-3 hover:bg-gray-100 rounded-lg w-full">
										<ng-container *ngTemplateOutlet="itemRoute; context: { $implicit: item}"></ng-container>
									</a>
								}
							}
						</li>
					}
				</ul>

				<div class="border-t border-gray-200">
					<ul class="h-auto list-none block w-full">
						<li class="px-2 block hover:bg-gray-100 rounded-lg w-full">
							<a routerLink="/account" class="p-3 text-secondary flex items-center gap-3">
								<i class="fa-solid fa-user"></i>
								<h5 class="font-medium text-base">Mi cuenta</h5>
							</a>
						</li>
						<li class="px-2 block hover:bg-gray-100 rounded-lg w-full" (click)="logout()">
							<div class="p-3 text-secondary flex items-center gap-3">
								<i class="fa-solid fa-right-to-bracket"></i>
								<h5 class="font-medium text-base">Cerrar sesión</h5>
							</div>
						</li>
					</ul>
				</div>

			</div>
		</div>
	</div>
</div>


<ng-template #itemRoute let-item>
	<div class="w-full text-center">
		@if (item.icon) {
			@if(item.iconType == 'img') {
				<img [src]="item.icon" alt="icon" class="max-w-full">
			} @else {
				<i class="" [ngClass]="item.icon"></i>
			}
		}
	</div>
	<div class="w-full">
		<h5 class="font-medium text-base">{{ item.name }}</h5>
	</div>
</ng-template>
