<header id="headerMain">
	<nav class="flex items-center justify-between w-full max-w-full mx-auto">
		<div class="flex flex-wrap gap-8 items-center">

			<ng-container *ngIf="userCashier != 'cajas' ">
				<div class="flex items-center gap-3 sm:pl-5 pl-4">
					<div class="lg:block hidden">
						<a (click)="toggleSidebarDesk()"
							class="btn__sidebar_desktop cursor-pointer md:text-2xl text-xl block w-[25px] h-[25px]">
							<i class="fa-light fa-bars-sort"></i>
						</a>
					</div>
					<div class="lg:hidden block">
						<a class="btn__sidebar md:text-2xl text-xl block w-[25px] h-[25px] cursor-pointer">
							<i class="fa-light fa-bars-sort"></i>
						</a>
					</div>
				</div>
			</ng-container>

			<!-- <ng-container>
				<ul class="routes__history">
					<li>
						<a routerLink="/">
							<img src="/assets/img/icons/home-outline.svg" alt="Home" title="Home">
						</a>
					</li>
					<ng-container *ngIf="(data$ | async) as _historyRoutes">
						@for (item of _historyRoutes; track $index) {
							<li class="route__item {{ item.active }}">
								<i class="far fa-angle-right"></i>
								@if (item.active) {
									<span>{{ item.title }}</span>
								} @else {
									@if(item.external) {
										<a href="{{ item.url }}">{{ item.title }}</a>
									} @else {
										<a routerLink="{{ item.url }}">{{ item.title }}</a>
									}
								}
							</li>
						}
					</ng-container>
				</ul>

			</ng-container>	 -->
		</div>

		<div>	
			<!-- <button (click)="getEvents()">Eventos</button> -->
			<p class="text-secondary text-lg font-bold">{{titlePage}}</p>
		</div>

		<!-- <div class="w-auto max-w-full">
			<ul class="list-none flex flex-wrap items-center gap-2">
				<ng-container *ngIf="user$ | async as _data; elseNotificationsData">

					@if (userCashier != "cajas") {
						<li class="relative dropdown_contnt">
							<a routerLink="/notifications" class="lg:hidden flex text-primary text-lg bg-gray-100 py-2 px-2 rounded-full transition-all duration-300 ease-in-out hover:bg-primary hover:text-white">
								<span class="w-[22px] relative before:pb-[100%] before:block">
									<i class="far fa-bell absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2"></i>
								</span>
								<span *ngIf="_data.unread_notifications_count > 0" class="bg-primary text-white rounded-[8rem] min-w-[18px] h-[18px] text-[11px] flex items-center justify-center text-center px-[3px] absolute -top-[5px] -right-[5px]">
									{{ _data.unread_notifications_count }}
								</span>
							</a>
							<button [matMenuTriggerFor]="notificationsDropdown" (menuClosed)="closeMenu()" class="lg:flex hidden text-secondary text-lg py-2 px-2 rounded-full transition-all duration-300 ease-in-out hover:bg-primary hover:text-white">
								<span class="w-[22px] relative before:pb-[100%] before:block">
									<i class="far fa-bell absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2"></i>
								</span>
								<span *ngIf="_data.unread_notifications_count > 0" class="bg-primary text-white rounded-[8rem] min-w-[18px] h-[18px] text-[11px] flex items-center justify-center text-center px-[3px] absolute -top-[5px] -right-[5px]">
									{{ _data.unread_notifications_count }}
								</span>
							</button>
							<mat-menu #notificationsDropdown="matMenu" class="w-[400px] !max-w-full">
								<div class="py-2 px-4 whitespace-nowrap">
									<div class="w-full block mb-4">
										<div class="w-full border-b-[1px] border-b-gray-300">
											<div class="grid grid-cols-2 items-center gap-2 pb-2 px-4 w-full">
												<div class="col-span-1 w-full block">
													<h5 class="text-xl text-left">Notificaciones</h5>
												</div>
												<div class="col-span-1 w-full block text-right">
													<div routerLink="/notifications" class="p-2 cursor-pointer text-sky-500">
														<i class="fa-duotone fa-right"></i>
													</div>
												</div>
											</div>
										</div>
									</div>
									<ul class="w-full grid grid-cols-1 gap-2 overflow-y-scroll max-h-[350px] scrollbar_css min_bar">
										<ng-container *ngIf="xServices.lengthItems(_data.first_notifications); else elseNotifications">
											<li class="w-full" *ngFor="let item of _data.first_notifications">
												<a [routerLink]="item['url'] || ''" [title]="item['name']" class="py-2 px-4 w-full grid grid-cols-[minmax(0,50px)_minmax(0,1fr)] gap-4 items-center transition-all rounded bg-transparent hover:bg-gray-50 ">
													<div class="w-full block">
														<div class="w-full block relative before:block before:pb-[100%]">
															<span class="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2">
																<i class="fa-solid fa-bell-on text-[2rem] text-emerald-400"></i>
															</span>
														</div>
													</div>
													<div class="w-full block">
														<div class="w-full grid grid-cols-2 gap-2 items-center">
															<div class="col-span-1 w-full block">
															</div>
															<div class="col-span-1 w-full block text-right">
																<p class="text-xs text-gray-400 ">{{ item['time'] ? item['time'] : '00:00' }}</p>
															</div>
														</div>
														<div class="w-full block">
															<h5 class="block w-full text-base font-bold text-gray-600 m-0 leading-none">{{ item['name'] }}</h5>
															<ng-container *ngFor="let message of item['notification_messages']">
																<div class="block w-full truncate text-sm" [innerHtml]="message['message']"></div>
															</ng-container>
														</div>
													</div>
												</a>
											</li>
											<li class="w-full block">
												<button routerLink="/notifications" class="w-full block py-2 px-4 bg-transparent text-gray-600 border border-gray-200 rounded appearance-none leading-none hover:bg-sky-50 hover:text-sky-400 transition-all">Ver Todos</button>
											</li>
										</ng-container>
										<ng-template #elseNotifications>
											<li class="w-full">
												<p>No se encontraron notificaciones</p>
											</li>
										</ng-template>
									</ul>
								</div>
							</mat-menu>
						</li>
						
					}
				</ng-container>
				<li class="relative">
					<ng-container *ngIf="!authService.getAuth(); else elseAuth1">
						<div class="hidden sm:flex sm:flex-wrap sm:gap-2">
							<a routerLink="/auth/login">
								<button-cy label="Iniciar sesión" />
							</a>
							<a routerLink="/auth/register">
								<button-cy addClass="btn__second" label="Registrarse" />
							</a>
						</div>
					</ng-container>
					<ng-template #elseAuth1>
						<ng-container *ngIf="user$ | async as _user">
							<ng-template #titleCustom>
								<div class="normal-case text-gray-800 text-lg py-2 px-4 inline-flex flex-wrap items-center relative before:w-full before:h-full before:transition-all before:duration-300 before:ease-in-out before:absolute before:top-1/2 before:left-1/2 before:-translate-y-1/2 before:-translate-x-1/2 before:scale-50 before:bg-gray-100 before:rounded-xl before:z-[-1] before:opacity-0 hover:before:opacity-100 hover:before:scale-100">
									<div class="w-[40px] mr-[10px]">
										<img #imgHeaderUser [src]="_user.image ?? '/assets/img/user.png'" alt="User" (error)="imgHeaderUser.src='assets/img/user.png'">
									</div>
									<span class="sm:flex sm:flex-col hidden max-w-[115px]">
										<span [title]="_user.name" class="text-sm block text-left leading-[normal] font-semibold text-ellipsis whitespace-nowrap overflow-hidden max-w-full">{{ _user.name }}</span>
										<span class="text-xs block text-left leading-[normal]"><span [innerHTML]="'@'"></span>{{ _user.username }}</span>
									</span>
									<small><i class="fa-light fa-angle-down sm:ml-[15px]"></i></small>
								</div>
							</ng-template>
							<dropdown-menu-cy [title]="titleCustom" [customTitle]="true" position="right">
								<ul class="flex flex-col">
									@if(authService.getAuth()) {
										<li>
											<a routerLink="/account" class="text-secondary grid grid-cols-[minmax(0,30px)_minmax(0,1fr)] p-3 hover:bg-gray-100 rounded-lg w-full">
												<div class="w-full text-center">
													<img src="/assets/img/header/account.png" alt="icon" class="max-w-full">
												</div>
												<div class="w-full">
													<h5 class="font-medium text-base">Mi Cuenta</h5>
													<p class="font-light text-sm">Administra y controla tu perfil</p>
												</div>
											</a>
										</li>
										<li>
											<button (click)="logout()" class="text-secondary grid grid-cols-[minmax(0,30px)_minmax(0,1fr)] p-3 hover:bg-gray-100 rounded-lg w-full">
												<div class="w-full text-center">
													<img src="/assets/img/header/logout.png" alt="icon" class="max-w-full">
												</div>
												<div class="w-full text-left">
													<h5 class="font-medium text-base">Cerrar sesión</h5>
												</div>
											</button>
										</li>
									}
								</ul>
							</dropdown-menu-cy>
						</ng-container>
					</ng-template>
				</li>
			</ul>
		</div> -->

		
	</nav>
</header>
