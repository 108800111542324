import {Injectable, Injector} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {mergeMap, delay, retryWhen, catchError, switchMap} from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})

export class TokenInterceptorService implements HttpInterceptor {

  constructor(private inject:Injector) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let authservice = this.inject.get(AuthService);

    // * Habilitar cuando se tenga servicio del refresh token
    let bearerToken = request.clone({});
    if(authservice.getToken()) {
      bearerToken = request.clone({
        setHeaders: {
          Authorization: 'Bearer ' + authservice.getToken()
        }
      });
    }

    return next.handle(bearerToken).pipe(
      catchError(errorData => {
        console.log(errorData, "este error");
        if(errorData.status === 401) {
          //console.log("entra aqui.........")
          //authservice.logout('expired');
          return throwError(errorData);
        }
        return throwError(errorData);
      })
    );
  }


  addTokenHeader(request: HttpRequest<any>, token: any) {
    return request.clone({ headers: request.headers.set('Authorization', 'Bearer '+ token) });
  }
}


export const maxRetries = 2;
export const delayMs = 2000;

export class ErroInterceptorService implements HttpInterceptor {
  
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      retryWhen((error) => {
        return error.pipe(
          mergeMap((error, index) => {
            if (error.status == 401) {
              console.log(error,);
            } else {
              if (index < maxRetries && error.status == 500) {
                return of(error).pipe(delay(delayMs));
              }
            }

            throw error;
          })
        )
      })
    )
  }
}
